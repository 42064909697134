import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer'
import Header from '../../components/header'

import './error.scss'

const PageNotFound = ({ notFoundLabelData, footerLabelData }) => {
  const h1Ref = useRef(null)
  const { introduction, title, linkText } = notFoundLabelData
  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
  }, [])

  return (
    <>
      <a className="skiplink visually-hidden o-header__button-skip" href="#main">
        Skip to content
      </a>
      <div className="container error-container u-margin-bottom-xl">
        <Header />
        <main id="main" className="detail-layout">
          <div className="error-inner u-container">
            <h1
              className="u-margin-bottom u-margin-top-xs"
             
              ref={h1Ref}
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <h4 className="u-text-readable u-margin-bottom paragraph" dangerouslySetInnerHTML={{ __html: introduction }} />

            <Link
              to="/"
              className="standalone-link back"
              dangerouslySetInnerHTML={{ __html: linkText }}
            />
          </div>
        </main>
      </div>
      <Footer footer={footerLabelData} />
    </>
  )
}
export default PageNotFound
