import React, { useState } from 'react'
import ConfirmModal from './confirmModal'

import './styles.scss'

const RestartCard = ({ restartCard = {} }) => {
  const [state, setState] = useState({
    showPopup: false,
  })
  const handleModal = () => {
    setState((st) => ({
      ...st,
      showPopup: true,
    }))
  }
  const hideModal = () => {
    setState((st) => ({
      ...st,
      showPopup: false,
    }))
  }

  return (
    <>
      {state.showPopup && (
        <ConfirmModal show={state.showPopup} handleHideModal={hideModal} />
      )}
      <section className="highlight highlight--left restart-card m-alert m-alert--inline m-alert--success">
        <div className="highlight__inner">
          <h2 className="h5"
            dangerouslySetInnerHTML={{
              __html: restartCard.restart_card_title,
            }}
          />

          <p
            className="u-margin-bottom-xs"
            dangerouslySetInnerHTML={{
              __html: restartCard.restart_card_subtitle,
            }}
          />

          <div className="links">
            <button
              type="button"
              className="a-button a-button--success a-button--s"
              onClick={handleModal}
              dangerouslySetInnerHTML={{
                __html: restartCard.restart_card_button_text,
              }}
            />
          </div>
        </div>
      </section>
    </>
  )
}
export default RestartCard
