import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { useSelector } from 'react-redux'
import HeatMap from './heatMap'

import './heatModal.scss'

const HeatMapModal = (props) => {
  const { show, handleHideModal } = props
  const { modal } = useSelector((state) => state.common)
  const modalRef = useRef(null)

  useEffect(() => {
    if (show) {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    }

    return () => {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
      document.getElementsByTagName('body')[0].style.overflow = 'initial'
    }
  }, [show])

  const children = (
    <div
      ref={modalRef}
      id="heat-map-modal"
      className={
        show ? 'modal modal--fixed-height visible' : 'modal modal--fixed-height'
      }
      role="dialog"
      aria-modal="true"
      aria-labelledby="heat-map-modal-title"
    >
      <div className="modal-inner map-modal_resp">
        <div className="modal-header">
          <button
            type="button"
            className="button close icon-cross modal-close"
            data-target="heat-map-modal"
            onClick={handleHideModal}
            aria-expanded="false"
          >
            <span>Sluiten</span>
          </button>
        </div>

        <div className="modal-content heat-loss-modal-content">
          <h3
            id="heat-map-modal-title"
            dangerouslySetInnerHTML={{ __html: modal.heat_map_title }}
          />
          <p dangerouslySetInnerHTML={{ __html: modal.heat_map_description }} />
          {/* TODO: Disabled map until correct map sources are received */}
          {/* <HeatMap /> */}
          {/* <p dangerouslySetInnerHTML={{ __html: modal.heat_map_footer }} /> */}
          {/* <div className="dt-heat-map" /> */}
        </div>
        <div className="modal-actions">
          <button
            type="button"
            className="a-button a-button--s modal-close"
            data-target="modal-meerInfo"
            onClick={handleHideModal}
          >
            Terug naar deze stap
          </button>
        </div>
      </div>
      <div
        className="modal-overlay modal-close"
        data-target="heat-map-modal"
        onMouseDown={handleHideModal}
        role="button"
        aria-label="modal"
      />
    </div>
  )

  return createPortal(
    show ? <FocusTrap>{children}</FocusTrap> : children,
    document.getElementsByTagName('body')[0],
  )
}
export default HeatMapModal
