import React from 'react'
import { footerImage } from '../../images/svg'

const Footer = ({ footer = {} }) => {
  return (
    <footer id="main-content" className="mtn-o-doormat">
      <div className="container mtn-o-doormat__navigation">
        <section className="footer u-wrapper">
          <h2 className="visually-hidden">Footer</h2>
          <div className="footer-top u-container">
            <div className="content-container mtn-o-doormat__box row">
              <div className="footer--column col-xs-12 col-md-6 col-lg-6">
                <div className="gentinfo-block">
                  <figure className="gentinfo-logo-custom">
                    {footerImage}
                  </figure>
                  <div className="gentinfo-block-content">
                    <h2
                      dangerouslySetInnerHTML={{ __html: footer.contact_title }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: footer.contact_paragraph,
                      }}
                    />

                    <ul className="icon-list ">
                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-phone"
                          aria-hidden="true"
                          title="Phone"
                        ></i>${footer.contact_no}`,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-envelope"
                          aria-hidden="true"
                          title="Envelope"
                          ></i>${footer.contact_email}`,
                        }}
                      />

                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-url"
                          aria-hidden="true"
                          title="Icon URL"
                          ></i>${footer.contact_url}`,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer--column col-xs-12 col-md-6 col-lg-6">
                <div
                  className="dg-block-disclaimer"
                  dangerouslySetInnerHTML={{ __html: footer.disclaimer_list }}
                />
              </div>
              <div className="footer--column col-xs-12 mtn-o-doormat__social">
                <div className="dg-block-post-address u-container">
                  <h3
                    className="h5"
                    dangerouslySetInnerHTML={{ __html: footer.social_title }}
                  />
                  <div className="social-list">
                    <ul>
                      <li>
                        <a href={footer.icon_1_link}>
                          <i
                            aria-hidden="true"
                            className="icon-facebook"
                            title="Facebook"
                          />
                          <span className="ai ai--facebook">
                            <svg width="100px" height="100px"><use href="/public/icons.svg#ai-social-media-facebook" /></svg>
                          </span>
                          <span className="visually-hidden">
                            {footer.icon_1_text}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href={footer.icon_2_link}>
                          <span className="ai ai--instagram">
                            <svg width="100px" height="100px"><use href="/public/icons.svg#ai-social-instagram" /></svg>
                          </span>
                          <i
                            aria-hidden="true"
                            className="icon-instagram"
                            title="Instagram"
                          />
                          <span className="visually-hidden">
                            {footer.icon_2_text}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href={footer.icon_3_link}>
                          <span className="ai ai--linkedin">
                            <svg width="100px" height="100px"><use href="/public/icons.svg#ai-professional-network-linkedin" /></svg>
                          </span>
                          <i
                            aria-hidden="true"
                            className="icon-linkedin"
                            title="LinkedIn"
                          />
                          <span className="visually-hidden">
                            {footer.icon_3_text}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom content-container">
            <div
              className="footer--column"
              dangerouslySetInnerHTML={{ __html: footer.bottom_list }}
            />

            <div className="footer--column partnership">
              <span>In samenwerking met District09</span>
              <i aria-hidden="true" className="icon-district09" />
            </div>
          </div>
        </section>
      </div>
    </footer>
  )
}
export default Footer
