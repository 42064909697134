import React, { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import {
  getEnergyTitle,
  getModalSelectedOption,
  getObjValuesSum,
  getOverviewValues,
} from '../../utils/helpers'
import { getSelectedDropdownOption } from '../../utils/resultHelpers'

export default function OverviewCard(props) {
  const {
    title,
    investeer,
    betaal_energielening,
    ontvang_premies,
    verbruik_minder_energie,
    bespaar_CO2,
    subtitle,
    bottomText,
    bottomLinkText,
    bottomLinkURL,
    values,
    page6Answer,
    localState,
    localImprovements,
    improvmentCards,
  } = props
 
  const split = JSON.parse(localStorage.getItem('split'))

  const dispatch = useDispatch()
  const selectedOption = useMemo(
    () => getModalSelectedOption('Verwarming', localState, Cookies.get("userId"), 0),
    [],
  )
  
  const [co2Value, setCO2Value] = useState(0)
  const [minderEnergie, setMinderEnergieValue] = useState(0)
  const [gas, setGasValue] = useState(0)
  const [electricity, setElectricityValue] = useState(0)
  const [bespaar, setBespaarValue] = useState(0)
  const [selectVal, setSelectValue] = useState(selectedOption)
  const minderEnergieZonnepanelen = values.minderEnergie.zonnepanelen || 0
  const investeerVal = getObjValuesSum(values.investeer)
  const bespaarVal = getObjValuesSum(values.bespaar)
  const premiesVal = getObjValuesSum(values.premies)
  const minderEnergieVal = getObjValuesSum(values.minderEnergie)
  const selectedDropdownValue = getSelectedDropdownOption(selectVal)
  const gasVal = getObjValuesSum(values.gas)
  const electricityVal = getObjValuesSum(values.electricity)
  const co2Val = getObjValuesSum(values.co2)

  const page6 = localState?.[6] && localState?.[6].answerNo
  const page7 = localState?.[7] && localState?.[7].answerNo

  const optionNo = localStorage.getItem('optionNo')

  useEffect(() => {
    getOverviewValues(
      localState,
      localImprovements,
      setMinderEnergieValue,
      setCO2Value,
      setBespaarValue,
      setElectricityValue,
      setGasValue,
      Cookies.get("userId"),
      values,
      dispatch,
    )
  }, [localImprovements])
  const splitedVerwarmingCard = improvmentCards?.find(
    (item) => item.title === 'Verwarming',
  )

  const verwarmingCardStatus = splitedVerwarmingCard?.status

  return (
    <section
      id="pdf-card-overview"
      className="highlight save highlight--left"
      style={{ breakInside: 'avoid', paddingTop: '2.5vh' }}
    >
      <div className="highlight__inner with-pig-icon">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />

        <div className="contact-details-column">
          <ul className="over-saving-list">
            <li>
              <p dangerouslySetInnerHTML={{ __html: investeer }} />
              <p>€ {Math.round(investeerVal)}</p>
            </li>
            <li>
              <p dangerouslySetInnerHTML={{ __html: betaal_energielening }} />
              <p>€ {Math.round(values.energielening)} / maand</p>
            </li>
            <li>
              <p dangerouslySetInnerHTML={{ __html: ontvang_premies }} />
              <p>€ {Math.round(premiesVal)}</p>
            </li>
            {split &&
            split !== 'false' &&
            (verwarmingCardStatus === 'green' ||
              verwarmingCardStatus === 'midgreen') ? (
              <>
                <li>
                  <p>Ik verbruik minder elektriciteit</p>
                  <p>
                    {Math.round(electricityVal) +
                      Math.round(minderEnergieZonnepanelen)}{' '}
                    kWh/jaar
                  </p>
                </li>
                <li>
                  <p>Ik verbruik minder {getEnergyTitle(page6, page7)}</p>
                  <p>{Math.round(gasVal)} kWh/jaar</p>
                </li>
              </>
            ) : (
              <li>
                <p
                  dangerouslySetInnerHTML={{ __html: verbruik_minder_energie }}
                />
                <p>
                  {Math.round(minderEnergieVal) < 0
                    ? 0
                    : Math.round(minderEnergieVal)}{' '}
                  kWh/jaar
                </p>
              </li>
            )}
            <li>
              <p
                style={{ whiteSpace: 'nowrap' }}
                dangerouslySetInnerHTML={{ __html: bespaar_CO2 }}
              />
              <p>{Math.round(co2Val)} kg</p>
            </li>
            <li>
              <strong>
                <p dangerouslySetInnerHTML={{ __html: subtitle }} />
              </strong>
              <p>
                <strong>€ {Math.round(bespaarVal)} / jaar</strong>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
