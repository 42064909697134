import React, { useRef, useEffect, useState, useMemo } from 'react'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import { improvmentCardsProps } from '../../utils/resultHelpers'
import AdviceContent from './adviceContent'
import Card from './card'
import ModalContent from './modalContent'
import OverviewCard from './overviewCard'
import Footer from './footer'
import { epc, epcMobile } from '../../images/svg/epc'
import { DUTCH_MONTHS } from '../../utils/constants'
import './pdf-dom.scss'
import '../result/result.scss'

const PdfDom = ({
  actualEpcLabel,
  epcVal,
  heatLossDataLabel,
  heatLossDataPrimairEnergie,
  heatLossDatakleurenbalk,
  resultLabelData,
  pdfLabelData,
  improvementLabelData,
  improvementModalData,
  disclaimerCardHeading,
  disclaimerCardURL,
  optionNo,
}) => {
  const {
    result: { heatLossData, ...overviewValues },
  } = useSelector((state) => state)
  const [state, setState] = useState({
    buildingData: [],
  })

  function stickToValidPercentage(value) {
    if (value < 0) {
      return 0
    }
    if (value > 100) {
      return 100
    }
    return value
  }
  const epcSpans = [
    { className: 'F', minValue: 500 },
    { className: 'E', minValue: 400 },
    { className: 'D', minValue: 300 },
    { className: 'C', minValue: 200 },
    { className: 'B', minValue: 100 },
    { className: 'A', minValue: 0 },
    { className: 'A+', minValue: -100 },
  ]
  const { result } = useSelector((state) => state)
  const improvmentCards = useMemo(() => {
    try {
      return Cookies.get("localState")
        ? improvmentCardsProps(
          JSON.parse(Cookies.get("localState")),
          JSON.parse(Cookies.get("localState")),
            improvementLabelData,
            Cookies.get("userId"),
            2,
          )
        : []
    } catch {
      return []
    }
  }, [Cookies.get("localState"), improvementLabelData])
  const updatedCards = useMemo(() => {
    return Cookies.get("localImprovements") && Cookies.get("localState")
      ? improvmentCardsProps(
          JSON.parse(Cookies.get("localImprovements")),
          JSON.parse(Cookies.get("localState")),
          improvementLabelData,
          Cookies.get("userId"),
          2,
        )
      : []
  }, [Cookies.get("localState"), Cookies.get("localImprovements"), improvementLabelData])
  const page25 = Cookies.get("localState")

  // EPC labels helper
  function getEPCLabels(category, value, catActual, valueActual, status) {
    if (catActual == '' && valueActual == '') {
      return (
        <>
          {epcSpans.map((span, index) => {
            const right = stickToValidPercentage(
              span.className === category ? value - span.minValue : 0,
            )

            const className = span.className === category ? 'visible' : 'hidden'
            return (
              <span
                style={{
                  visibility: `${className}`,
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  minWidth: '0',
                }}
                key={`${value}-${index.toString()}`}
              >
                <span
                  style={{
                    transform: 'translate(50%, 0)',
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    top: '50%',
                    right: `${right}%`,
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderTop: '10px solid #000000',
                  }}
                >
                  {/* <strong>{`${value} kWh/m²`}</strong> */}
                </span>
              </span>
            )
          })}
        </>
      )
    }
    if (status == 'sameLabel') {
      return (
        <>
          {epcSpans.map((span, index) => {
            const right = stickToValidPercentage(
              span.className === category ? value - span.minValue : 0,
            )

            const right1 = stickToValidPercentage(
              span.className === catActual ? valueActual - span.minValue : 0,
            )

            const className = span.className === category ? 'visible' : 'hidden'

            const spanClassName =
              span.className === category ? '#000000' : 'lightgray'

            const spanClassName1 =
              span.className === catActual ? 'lightgray' : '#000000'
            return (
              <span
                style={{
                  visibility: `${className}`,
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  minWidth: '0',
                }}
                key={`${value}-${index.toString()}`}
              >
                {right1 > 0 ? (
                  <>
                    <span
                      style={{
                        transform: 'translate(50%, 0)',
                        position: 'relative',
                        whiteSpace: 'nowrap',
                        top: '50%',
                        right: `${right1}%`,
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderTop: `10px solid ${spanClassName1}`,
                      }}
                    />
                    <span
                      style={{
                        transform: 'translate(50%, 0)',
                        position: 'relative',
                        whiteSpace: 'nowrap',
                        top: '50%',
                        right: `${right}%`,
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderTop: `10px solid ${spanClassName}`,
                      }}
                    />
                  </>
                ) : (
                  <span
                    style={{
                      transform: 'translate(50%, 0)',
                      position: 'relative',
                      whiteSpace: 'nowrap',
                      top: '50%',
                      right: `${right}%`,
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderTop: `10px solid ${spanClassName}`,
                    }}
                  />
                )}
              </span>
            )
          })}
        </>
      )
    }
    return (
      <>
        {epcSpans.map((span, index) => {
          const right = stickToValidPercentage(
            span.className === category
              ? value - span.minValue
              : span.className === catActual
              ? valueActual - span.minValue
              : 0,
          )
          const className =
            span.className === category
              ? 'visible'
              : span.className === catActual
              ? 'visible'
              : 'hidden'

          const spanClassName =
            span.className === category
              ? '#000000'
              : span.className === catActual
              ? 'lightgray'
              : '#000000'
          return (
            <span
              style={{
                visibility: `${className}`,
                flex: '1',
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '0',
              }}
              key={`${value}-${index.toString()}`}
            >
              <span
                style={{
                  transform: 'translate(50%, 0)',
                  position: 'relative',
                  whiteSpace: 'nowrap',
                  top: '50%',
                  right: `${right}%`,
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent',
                  borderTop: `10px solid ${spanClassName}`,
                }}
              >
                {/* <strong>{`${value} kWh/m²`}</strong> */}
              </span>
            </span>
          )
        })}
      </>
    )
  }
  const date = new Date()
  return (
    <div id="pdf-dom" className="container pdf-container">
      <header className="o-header">
        <a
          href="/"
          title="Home"
          className="o-header__logo"
          rel="home"
        >
          <img src="https://cdn.antwerpen.be/core_branding_scss/5.0.2/assets/images/a-logo.svg" alt="Homepagina Antwerpen - Check je huis" />
        </a>
        <div className="content-container" style={{ minHeight: 62 }}>
          <a
            href="/"
            title="Home"
            className="site-logo "
            rel="home"
            style={{
              width: '82px',
              height: '57px',
              position: 'absolute',
              left: 0,
            }}
          >
            &nbsp;
          </a>
          <a
            href="/"
            title="Home"
            className="title simple"
            rel="home"
            dangerouslySetInnerHTML={{ __html: pdfLabelData.pdf_title }}
            style={{ position: 'absolute', left: '100px' }}
          />
          <span>
            Gemaakt op {date.getDate()} {DUTCH_MONTHS[date.getMonth()]}{' '}
            {date.getFullYear()}
          </span>
        </div>
        <hr />
      </header>
      <main className="detail-layout content-container pdf-content-container">
        <div
          className="intro"
          dangerouslySetInnerHTML={{ __html: pdfLabelData.pdf_intro }}
        />
        <hr />
        <div className="pdf-content">
          {improvmentCards.map((card, index) => {
            //             console.log(" data2[card.pageIndex]", improvmentCards)

            // console.log(" data2[card.pageIndex]", card)
            // console.log(" data2[card.pageIndex]", card.pageIndex)
            // console.log(" data2[card.pageIndex]",  cookies?.localState?.[card.pageIndex])


            const valid = Object.keys(
              JSON.parse(Cookies.get("localState"))[card.pageIndex] || [],
            ).length
            const showModalContent = !updatedCards?.find(
              (c) => c.title === card.title && c.status === card.status,
            )
            const tipKey = `pdf_${card.title
              ?.toLowerCase()
              ?.replace(' ', '_')
              ?.replace(' ', '_')}_tip`

            return valid ? (
              <div
                className=""
                style={{ breakInside: 'avoid', paddingTop: '.8vh' }}
              >
                <div
                  key={card.title}
                  id={card.title}
                  className={`improvement ${
                    improvmentCards.length - 1 === index && 'no-after'
                  }`}
                >
                  <div className="card-content">
                    <Card {...card} />
                  </div>
                  <div className="imporved-content">
                    {showModalContent && (
                      <ModalContent
                        {...updatedCards.find((c) => c.title === card.title)}
                        cookies={Cookies}
                        calculations={result}
                        labelData={pdfLabelData}
                        modalTitle={card.title}
                        page25={page25}
                        optionNo={optionNo}
                        pages={JSON.parse(Cookies.get("localState"))}
                      />
                    )}
                    <AdviceContent {...card} cookies={Cookies} />
                    {pdfLabelData[tipKey]
                      ?.replace(/(<([^>]+)>)/gi, '')
                      ?.trim() && (
                      <div
                        className="messages messages--info"
                        role="alert"
                        aria-atomic="true"
                      >
                        {' '}
                        <i className="icon-info" aria-hidden="true" />{' '}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: pdfLabelData[tipKey],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          })}
          <div key="small-text" className="improvement">
            <div className="card-content" />
            <div className="imporved-content">
              <p
                style={{ fontSize: '14px' }}
                dangerouslySetInnerHTML={{
                  __html: improvementModalData.improvement_modal_small_text,
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ breakInside: 'avoid', paddingTop: '.8vh' }}>
          <OverviewCard
            title="Overzicht van je besparing"
            investeer="Ik investeer"
            betaal_energielening="Ik betaal met de energielening"
            ontvang_premies="Ik ontvang premies"
            verbruik_minder_energie="Ik verbruik minder energie"
            bespaar_CO2="Ik bespaar CO2"
            subtitle="Ik bespaar"
            bottomText="(*) Op basis van een rentevoet van 5 % en een looptijd van 10 jaar"
            bottomLinkText="meer informatie over de energielening"
            bottomLinkURL="https://stad.gent/nl/energiecentrale/energielening"
            values={result}
            page6Answer={JSON.parse(Cookies.get("localState"))}
            localState={JSON.parse(Cookies.get("localState"))}
            localImprovements={Cookies.get("localImprovements")}
            improvmentCards={updatedCards}
          />
          {heatLossData.kleurenbalk ? (
            <div
              style={{
                marginTop: '25px',
                paddingTop: '10px',
                bottom: '0',
                background: '#fff',
                width: '80%',
                margin: '0 auto',
                display: 'block !important',
              }}
            >
              <div style={{ position: 'relative' }}>
                {actualEpcLabel === heatLossData.label ? (
                  Cookies.get("bespaar") && Number(Cookies.get("bespaar")) > 0 ? (
                    <h4
                      className="heat-loss-title"
                      dangerouslySetInnerHTML={{
                        __html: `${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}
                        ${resultLabelData.epcCard.epc_img_title_mid_text} ${heatLossData.label} 
                      ${resultLabelData.epcCard.epc_img_title_last_text}`,
                      }}
                    />
                  ) : (
                    <h4
                      className="heat-loss-title"
                      dangerouslySetInnerHTML={{
                        __html: `${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}`,
                      }}
                    />
                  )
                ) : (
                  <h4
                    className="heat-loss-title"
                    dangerouslySetInnerHTML={{
                      __html: `${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}, 
                      ${resultLabelData.epcCard.epc_img_title_mid_text} ${heatLossData.label} 
                      ${resultLabelData.epcCard.epc_img_title_last_text}`,
                    }}
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '20px',
                      display: 'flex',
                    }}
                  >
                    {epcVal !== heatLossData.primairEnergieverbruikPerM2
                      ? actualEpcLabel !== heatLossData.label
                        ? getEPCLabels(
                            heatLossData.label,
                            heatLossData.primairEnergieverbruikPerM2,
                            actualEpcLabel,
                            epcVal,
                            '',
                          )
                        : getEPCLabels(
                            heatLossData.label,
                            heatLossData.primairEnergieverbruikPerM2,
                            actualEpcLabel,
                            epcVal,
                            'sameLabel',
                          )
                      : getEPCLabels(
                          heatLossData.label,
                          heatLossData.primairEnergieverbruikPerM2,
                          '',
                          '',
                          '',
                        )}
                  </div>
                  <div style={{ width: '100%', marginTop: '0' }}>{epc}</div>
                </div>
                <hr />
                <div style={{ display: 'flex', paddingBottom: '10px' }}>
                  <span style={{ alignItems: 'center' }}>
                    {Cookies.get("bespaar") && Number(Cookies.get("bespaar")) > 0 ? (
                      <strong
                        dangerouslySetInnerHTML={{
                          __html: `${
                            resultLabelData.epcCard.epc_left_calculation_text
                          } ${
                            resultLabelData.epcCard.epc_calculation_currency
                          } ${Math.round(Number(Cookies.get("bespaar")))} / ${
                            resultLabelData.epcCard.epc_left_calculation_unit
                          }`,
                        }}
                      />
                    ) : null}
                  </span>
                </div>

                <span className="link">
                  {disclaimerCardHeading}:{' '}
                  <a href={disclaimerCardURL} style={{ content: '' }}>
                    {disclaimerCardURL}{' '}
                  </a>
                </span>
                <hr style={{ padding: '6px', visibility: 'hidden' }} />
              </div>
            </div>
          ) : (
            ''
          )}

          <hr />
          <Footer labelsData={pdfLabelData} />
          <hr />
        </div>
      </main>
    </div>
  )
}
export default PdfDom
