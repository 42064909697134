import React from 'react'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

function removeCookies() {
  cookies.remove('existingUser')
  cookies.remove('userId')
  cookies.remove('localState')
  window.location.reload()
}

const Header = () => {
  return (
    <header className="o-header">
      <a
          href="/"
          title="Home"
          className="o-header__logo"
          rel="home"
        >
          <img src="https://cdn.antwerpen.be/core_branding_scss/5.0.2/assets/images/a-logo.svg" alt="Homepagina Antwerpen - Check je huis" />
      </a>
      <div className="content-container">
        <a
          href="/"
          title="Home"
          className="site-logo "
          rel="home"
          style={{ width: '82px', height: '57px' }}
        >
          &nbsp;
        </a>
        <a
          href="/"
          onClick={removeCookies}
          title="Home"
          className="title simple"
          rel="home"
        >
          Check je huis
        </a>
      </div>
      <hr />
    </header>
  )
}
export default Header
