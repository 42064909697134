import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ModalMeerInfo from '../modal/modalMeerInfo'
import { API_URL } from '../../environment'

const MoreInfoButton = (props) => {
  const [labelText, setLabelText] = useState('')
  const { desktop, phone, modalData, answerSetKey } = props
  const { isLoading } = useSelector((state) => state.questionnaire)
  const [showModal, setShowModal] = useState(false)
  const {
    title,
    content,
    subtitle,
    subcontent,
    linkText,
    linkUrl,
    buttonText,
  } = modalData[answerSetKey]

  const handleShowModal = () => {
    setShowModal(!showModal)
  }
  const handleHideModal = () => {
    setShowModal(!showModal)
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false)
    }
  }, [])

  const getLabelText = () => {
    axios.get(API_URL + `/page/3`).then((response) => {
      setLabelText(response.data[0].pageContent[0])
    })
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  useEffect(getLabelText, [])
  return (
    <>
      {showModal && (
        <ModalMeerInfo
          modalTitle={title}
          modalContent={content}
          modalSubTitle={subtitle}
          modalSubContent={subcontent}
          modalLinkText={linkText}
          modalLinkURL={linkUrl}
          modalBtnText={buttonText}
          show={showModal}
          handleHideModal={handleHideModal}
        />
      )}
      {desktop && (
        <button
          onClick={() => handleShowModal()}
          type="button"
          className="a-button a-button--s"
          aria-controls="modal-meerInfo"
          aria-expanded="false"
        >
          {labelText ? labelText.value : ''}
        </button>
      )}
      {!isLoading && phone && (
        <button
          onClick={() => handleShowModal()}
          type="button"
          className="a-button button-block button-large external-button"
          aria-controls="modal-meerInfo"
          aria-expanded="false"
        >
          {labelText ? labelText.value : ''}
        </button>
      )}
    </>
  )
}
export default MoreInfoButton
