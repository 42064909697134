import React, { useState } from 'react'

import HeatMapModal from '../modal/heatMapModal'
import './questionnaire-extra.scss'
import mapicon from '../../images/map_marker_icon.svg'
export function mock() {}

export function HoofdakExtra() {
  const [showModalMap, setShowModalMap] = useState(false)
  const handleShowModalMap = () => {
    setShowModalMap(true)
  }

  const handleHideModalMap = () => {
    setShowModalMap(false)
  }
  return (
    <>
      {showModalMap && (
        <HeatMapModal
          show={showModalMap}
          handleHideModal={handleHideModalMap}
        />
      )}
      <div className="hoofdak-extra">
        <p className="u-margin-bottom-xs">
          <strong>
            Weet je het niet? Bekijk je dak op de warmtefoto van de Stad Antwerpen.
          </strong>
        </p>
        <button type="button" onClick={handleShowModalMap} className="a-button">
          <img src={mapicon} className="map-image" />
          {'  '}
          Bekijk je dak op de warmtefoto
        </button>
      </div>
    </>
  )
}
