import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import jsCookie from 'js-cookie'
import Header from '../../components/header'
import Footer from '../../components/footer'
import HomeAddressForm from './homeAddressForm'
import { appendRoute, getCookieMeta, setPageTitle } from '../../utils/helpers'
import { pageTitles } from '../../utils/constants'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const Home = ({ homeLabelData, footerLabelData }) => {
  setPageTitle(pageTitles.home)
  const h1Ref = useRef(null)

  function removeCookies() {
    cookies.remove('localState')
    cookies.remove('localState')
    cookies.remove('localImprovements')
    cookies.remove('bespaar')
    localStorage.clear()
  }

  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
    jsCookie.set('existingUser', true, getCookieMeta())
  }, [])

  return (
    <>
      <a className="skiplink visually-hidden o-header__button-skip" href="#main">
        Skip to content
      </a>
      <div className="container home-container u-margin-bottom-xl">
        <Header />
        <main id="main" className="detail-layout content-container">
          <div className="home-content u-container">
            <h1
              className="u-margin-bottom u-margin-top-xs"
             
              ref={h1Ref}
              dangerouslySetInnerHTML={{ __html: homeLabelData.heading }}
            />
            <div
              className="home-intro u-text-readable paragraph u-text-bold"
              dangerouslySetInnerHTML={{ __html: homeLabelData.introduction }}
            />
            <div className="content-container form-section-home u-margin-bottom">
              <HomeAddressForm homeLabelData={homeLabelData} />
            </div>
            <p className="link-action">
              <Link
                onClick={removeCookies}
                to={{
                  pathname: appendRoute(1, undefined, 'typewoning'),
                  state: { fromPage: 0 },
                }}
                className="standalone-link"
                dangerouslySetInnerHTML={{ __html: homeLabelData.linkText }}
              />
            </p>
          </div>
        </main>
      </div>
      <Footer footer={footerLabelData} />
    </>
  )
}
export default Home
