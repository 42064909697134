import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { useHistory } from 'react-router-dom'

import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultState } from '../../../store/actions'
import Cookies from 'js-cookie'

const defaultPages = {
  1: {},
  2: {},
  3: { oppervlakte_input: 0 },
  4: {},
  5: {},
  6: {},
  7: {},
  8: {},
  9: {},
  23: { aantal_zonnepanelen: '' },
  26: {
    aardgasverbruik: 0,
    elektriciteitsverbruik: 0,
    zonnepanelen: 0,
    energieproductie: 0,
  },
}
const ConfirmModal = (props) => {
  const { show, handleHideModal } = props
  const { modal } = useSelector((state) => state.common)
  const modalRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleReset = () => {
    Cookies.remove('localState')
    Cookies.remove('localImprovements')
    Cookies.remove('existingUser')
    Cookies.remove('userId')
    Cookies.remove('bespaar')
    localStorage.clear()
    dispatch(setDefaultState(defaultPages))

    history.push('/')
  }

  useEffect(() => {
    if (show) {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
    } else {
      modalRef.current.blur()
      document.getElementById('root').setAttribute('aria-hidden', true)
    }
  }, [show])

  const children = (
    <div
      ref={modalRef}
      id="modal-actions"
      className={show ? 'modal visible' : 'modal'}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-actions-title"
    >
      <div className="modal-inner">
        <div className="modal-header">
          <button
            type="button"
            className="button close icon-cross modal-close"
            data-target="modal-actions"
            onClick={handleHideModal}
          >
            <span>Sluiten</span>
          </button>
        </div>
        <div className="modal-content">
          <h3
            id="modal-actions-title"
            dangerouslySetInnerHTML={{
              __html: modal.restart_modal_title,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: modal.restart_modal_description,
            }}
          />
        </div>
        <div className="modal-actions">
          <button
            type="button"
            className="a-button a-button--s"
            data-target="modal-actions"
            onClick={handleReset}
            dangerouslySetInnerHTML={{
              __html: modal.restart_modal_yes_button_text,
            }}
          />
          <button
            type="button"
            className="a-button a-button--s a-button-outlined modal-close button-cancel"
            data-target="modal-actions"
            onClick={handleHideModal}
            dangerouslySetInnerHTML={{
              __html: modal.restart_modal_no_button_text,
            }}
          />
        </div>
      </div>

      <div
        className="modal-overlay modal-close"
        data-target="modal-actions"
        // onMouseDown={handleHideModal}
        role="button"
        aria-label="modal"
      />
    </div>
  )

  return createPortal(
    show ? <FocusTrap>{children}</FocusTrap> : children,
    document.getElementsByTagName('body')[0],
  )
}
export default ConfirmModal
