import React, { useEffect, useRef } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { setPageTitle } from '../../utils/helpers'
import { pageTitles } from '../../utils/constants'
import AdviceCard from '../../components/adviceCard'
import './getAdvice.scss'

const GetAdvice = ({ adviceLabelData, footerLabelData }) => {
  setPageTitle(pageTitles.home)
  const h1Ref = useRef(null)

  const { adviceCard, title, introduction, description } = adviceLabelData

  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
  }, [])

  return (
    <>
      <a className="skiplink visually-hidden o-header__button-skip" href="#advice">
        Skip to content
      </a>
      <div className="container advice-container u-margin-bottom-xl">
        <Header />
        <main id="advice" className="detail-layout content-container">
          <div className="advice-content u-container u-margin-bottom-xl">
            <h1
              className="u-margin-bottom u-margin-top-xs"
             
              ref={h1Ref}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="advice-intro u-text-bold u-margin-bottom-xs u-text-readable paragraph"
              dangerouslySetInnerHTML={{ __html: introduction }}
            />
            <div
              className="advice-description u-text-readable paragraph"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="u-container">
            <AdviceCard
              adviceCard={adviceCard}
              dynamicClass="appartment-advice-card"
            />
          </div>
        </main>
      </div>
      <Footer footer={footerLabelData} />
    </>
  )
}
export default GetAdvice
