import React, { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import {
  getObjValuesSum,
  getOverviewValues,
  getCookieMeta,
  getModalSelectedOption,
  getEnergyTitle,
} from '../../utils/helpers'
import './overviewSavingCard.scss'
import { getSelectedDropdownOption } from '../../utils/resultHelpers'
import { setInvesteer, setPremies } from '../../store/actions'

const OverviewSavingCard = (props) => {
  const {
    savingCard,
    values,
    page6Answer,
    localState,
    maximumbedragEnergielening,
    localImprovements,
    improvmentCards,
  } = props

  let split = JSON.parse(localStorage.getItem('split'))
  const selectedOption = useMemo(
    () => getModalSelectedOption('Verwarming', JSON.parse(localState), Cookies.get("userId"), 0),
    [],
  )
  const dispatch = useDispatch()
  const [co2Value, setCO2Value] = useState(0)
  const [gas, setGasValue] = useState(0)
  const [electricity, setElectricityValue] = useState(0)
  const [minderEnergie, setMinderEnergieValue] = useState(0)
  const [bespaar, setBespaarValue] = useState(0)
  const minderEnergieVal = getObjValuesSum(values.minderEnergie)
  const minderEnergieZonnepanelen = values.minderEnergie.zonnepanelen || 0
  const investeerVal = getObjValuesSum(values.investeer) 
  const investeerValLoan = localStorage.getItem('investeerValLoan')
  const bespaarVal = getObjValuesSum(values.bespaar)
  const premiesVal = getObjValuesSum(values.premies)
  const co2Val = getObjValuesSum(values.co2)
  const gasVal = getObjValuesSum(values.gas)
  const electricityVal = getObjValuesSum(values.electricity)
  const page6 = localState && JSON.parse(localState)[6] && JSON.parse(localState)[6].answerNo
  const page7 = localState && JSON.parse(localState)[7] && JSON.parse(localState)[7].answerNo

  const paramsResponse = JSON.parse(localStorage.getItem('parameters'))

  const parameters = paramsResponse?.data?.data?.[0] || []
  const durationParam =
    parameters.find((param) => param?.id === '23')?.value || 0
  const interestRateParam =
    parameters.find((param) => param?.id === '22')?.value || 0

  useEffect(() => {
    const investerKeys = Object.keys(values.investeer).reduce((obj, value) => {
      return {
        ...obj,
        [value]: 0,
      }
    }, {})
    const premieKeys = Object.keys(values.premies).reduce((obj, value) => {
      return {
        ...obj,
        [value]: 0,
      }
    }, {})
    dispatch(setInvesteer(investerKeys))
    dispatch(setPremies(premieKeys))
  }, [])

  useEffect(() => {
    Cookies.get("localImprovements")
      ? getOverviewValues(
        JSON.parse(Cookies.get("localState")),
        JSON.parse(Cookies.get("localImprovements")),
          () => {},
          () => {},
          () => {},
          () => {},
          () => {},
          Cookies.get("userId"),
          values,
          dispatch,
        )
      : null

    split = JSON.parse(localStorage.getItem('split'))
  }, [Cookies.get("localImprovements")])

  useEffect(() => {
    Cookies.set('bespaar', bespaarVal, getCookieMeta())
  }, [bespaarVal])
  const splitedVerwarmingCard = improvmentCards?.find(
    (item) => item.title === 'Verwarming',
  )
  const verwarmingCardStatus = splitedVerwarmingCard?.status
  return (
    <section
      id="result-card-overview"
      className="highlight save highlight--top overview-saving-card u-bg"
    >
      <div className="highlight__inner with-pig-icon u-margin">
        <h2
          className="h3 u-margin-bottom-xs"
          dangerouslySetInnerHTML={{ __html: savingCard.saving_card_title }}
        />
        <div className="contact-details-column u-margin-bottom-xs">
          <ul className="over-saving-list">
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: savingCard.saving_card_investeer,
                }}
              />
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: savingCard.saving_card_currency,
                  }}
                />{' '}
                {investeerVal}
              </p>
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: savingCard.saving_card_betaal_energielening,
                }}
              />
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: savingCard.saving_card_currency,
                  }}
                />{' '}
                {Math.round(values.energielening)} /{' '}
                {savingCard.saving_card_betaal_energielening_unit}
              </p>
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: savingCard.saving_card_ontvang_premies,
                }}
              />
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: savingCard.saving_card_currency,
                  }}
                />{' '}
                {premiesVal}
              </p>
            </li>
            {split &&
            split !== 'false' &&
            (verwarmingCardStatus === 'green' ||
              verwarmingCardStatus === 'midgreen') ? (
              <>
                <li>
                  <p>Ik verbruik minder elektriciteit{split}</p>
                  <p>
                    {Math.round(electricityVal) +
                      Math.round(minderEnergieZonnepanelen)}{' '}
                    {savingCard.saving_card_verbruik_minder_energie_unit}
                  </p>
                </li>
                <li>
                  Ik verbruik minder {getEnergyTitle(page6, page7)}
                  <p>
                    {Math.round(gasVal)}{' '}
                    {savingCard.saving_card_verbruik_minder_energie_unit}
                  </p>
                </li>
              </>
            ) : (
              <li>
                <p
                  dangerouslySetInnerHTML={{
                    __html: savingCard.saving_card_verbruik_minder_energie,
                  }}
                />
                <p>
                  {Math.round(minderEnergieVal)}{' '}
                  {savingCard.saving_card_verbruik_minder_energie_unit}
                </p>
              </li>
            )}

            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: savingCard.saving_card_bespaar_CO2,
                }}
              />
              <p>
                {Math.round(co2Val)} {savingCard.saving_card_bespaar_CO2_unit}
              </p>
            </li>
            <li>
              <p>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: savingCard.saving_card_subtitle,
                  }}
                />
              </p>
              <p>
                <strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: savingCard.saving_card_currency,
                    }}
                  />{' '}
                  {split &&
                  split !== 'false' &&
                  (verwarmingCardStatus === 'green' ||
                    verwarmingCardStatus === 'midgreen')
                    ? Math.round(bespaarVal)
                    : Math.round(bespaarVal < 0 ? 0 : bespaarVal)}
                  / {savingCard.saving_card_subtitle_unit}
                </strong>
              </p>
            </li>
          </ul>
        </div>
        <p className="mt-15 note_text">
          <span
            dangerouslySetInnerHTML={{
              __html: savingCard.saving_card_bottom_text,
            }}
          />{' '}
          {!savingCard.saving_card_bottom_link_text.startsWith('<br>') &&
          investeerValLoan > maximumbedragEnergielening ? (
            <a
              href={`${savingCard.saving_card_bottomLink_url}?duration=${durationParam}&interestRate=${interestRateParam}&total=${maximumbedragEnergielening}`}
              dangerouslySetInnerHTML={{
                __html: savingCard.saving_card_bottom_link_text,
              }}
              target="_blank"
            />
          ) : (
            <a
              href={`${savingCard.saving_card_bottomLink_url}?duration=${durationParam}&interestRate=${interestRateParam}&total=${investeerValLoan}`}
              dangerouslySetInnerHTML={{
                __html: savingCard.saving_card_bottom_link_text,
              }}
              target="_blank"
            />
          )}
        </p>
      </div>
    </section>
  )
}

export default OverviewSavingCard
