import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie';
import axios from 'axios'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import AdviceCard from '../../components/adviceCard'
import Footer from '../../components/footer'
import Header from '../../components/header'
import OntvangEmailCard from '../../components/ontvangEmailCard'
import { pageTitles, APP_URL } from '../../utils/constants'
import {
  getCookieMeta,
  getObjValuesSum,
  setPageTitle,
} from '../../utils/helpers'
import InfoCard from '../../components/infoCard'
import OverviewSavingCard from '../../components/overviewSavingCard'
import ImprovementCard from '../../components/improvementCard'

import {
  sendConsumptions,
  setHeatLossData,
  // getConsumptions,
} from '../../store/actions'
import { improvmentCardsProps } from '../../utils/resultHelpers'
import { epc, epcMobile } from '../../images/svg/epc'
import PdfDom from '../pdfDom'
import RestartCard from '../../components/restartCard'

function stickToValidPercentage(value) {
  if (value < 0) {
    return 0
  }
  if (value > 100) {
    return 100
  }
  return value
}

const epcSpans = [
  { className: 'F', minValue: 500 },
  { className: 'E', minValue: 400 },
  { className: 'D', minValue: 300 },
  { className: 'C', minValue: 200 },
  { className: 'B', minValue: 100 },
  { className: 'A', minValue: 0 },
  { className: 'A+', minValue: -100 },
]

// EPC labels helper
function getEPCLabels(category, value, catActual, valueActual, status) {
  if (catActual == '' && valueActual == '') {
    return (
      <>
        {epcSpans.map((span, index) => {
          const right = stickToValidPercentage(
            span.className === category ? value - span.minValue : 0,
          )
          const className = span.className === category ? 'active' : 'inactive'
          return (
            <span className={className} key={`${value}-${index.toString()}`}>
              <span style={{ right: `${right}%` }} className="span-value">
                {/* <strong>{`${value} kWh/m²`}</strong> */}
              </span>
            </span>
          )
        })}
      </>
    )
  }
  if (status == 'sameLabel') {
    return (
      <>
        {epcSpans.map((span, index) => {
          const right = stickToValidPercentage(
            span.className === category ? value - span.minValue : 0,
          )

          const right1 = stickToValidPercentage(
            span.className === catActual ? valueActual - span.minValue : 0,
          )

          const className = span.className === category ? 'active' : 'inactive'

          const spanClassName =
            span.className === category ? 'span-value' : 'span-value-update'

          const spanClassName1 =
            span.className === catActual ? 'span-value-update' : 'span-value'
          return (
            <span className={className} key={`${value}-${index.toString()}`}>
              {right1 > 0 ? (
                <>
                  <span
                    style={{ right: `${right1}%` }}
                    className={spanClassName1}
                  />
                  <span
                    style={{ right: `${right}%` }}
                    className={spanClassName}
                  />
                </>
              ) : (
                <span
                  style={{ right: `${right}%` }}
                  className={spanClassName}
                />
              )}
            </span>
          )
        })}
      </>
    )
  }
  return (
    <>
      {epcSpans.map((span, index) => {
        const right = stickToValidPercentage(
          span.className === category
            ? value - span.minValue
            : span.className === catActual
            ? valueActual - span.minValue
            : 0,
        )
        const className =
          span.className === category
            ? 'active'
            : span.className === catActual
            ? 'active'
            : 'inactive'

        const spanClassName =
          span.className === category
            ? 'span-value'
            : span.className === catActual
            ? 'span-value-update'
            : 'span-value'
        return (
          <span className={className} key={`${value}-${index.toString()}`}>
            <span style={{ right: `${right}%` }} className={spanClassName}>
              {/* <strong>{`${value} kWh/m²`}</strong> */}
            </span>
          </span>
        )
      })}
    </>
  )
}

const Result = ({ resultLabelData, footerLabelData, pdfLabelData }) => {
  setPageTitle(pageTitles.result)
  let clLogs
  const history = useHistory()

  const {
    result: { heatLossData, actualEpcLabel, ...overviewValues },
  } = useSelector((state) => state)
  const h1Ref = useRef(null)
  const dispatch = useDispatch()
  const {
    infoCard,
    adviceCard,
    emailCard,
    savingCard,
    improvementCard,
    improvementModal,
    restartCard,
    disclaimerCard,
  } = useMemo(() => resultLabelData, [resultLabelData])
  const [state, setState] = useState({
    buildingData: [],
    isAreaUpdated: {
      Zonnepanelen: false,
      Zonnewering: false,
      Groendak: false,
      Hoofddak: false,
    },
  })
  const [colorDot, setColorDot] = useState([])
  const [loaded, setLoaded] = useState(0)
  // Set Current state Epc lable
  const [epcVal, setEpcVal] = useState()

  const bespaarVal = useMemo(() => getObjValuesSum(overviewValues.bespaar), [
    overviewValues.bespaar,
  ])
  const improvmentCards = useMemo(() => {
    try {
      const value =
      Cookies.get("localImprovements") && Cookies.get("localState")
          ? improvmentCardsProps(
            JSON.parse(Cookies.get("localImprovements")),
            JSON.parse(Cookies.get("localState")),
              improvementCard,
              Cookies.get("userId"),
              0,
            )
          : []
            
      if (
        Cookies.get("userId") &&
        Cookies.get("localState") &&
        Cookies.get("localImprovements")
      ) {
        sendConsumptions(
          Cookies.get("userId"),
          JSON.parse(Cookies.get("localState")),
          JSON.parse(Cookies.get("localImprovements")),
        )
      }

      return value
    } catch {
      return []
    }
  }, [
    Cookies.get("userId"),
    Cookies.get("localImprovements"),
    Cookies.get("localState"),
    improvementCard,
  ])
  // event on browser back button
  window.addEventListener(
    'popstate',
    (event) => {
      history.pushState(null, null, 'no-back-button')
      if (event.state) {
        if (window.location.pathname === '/vraag/31/klimaat') {
          history.push({
            pathname: '/',
            state: { fromPage: 'result' },
          })
        }
      }
    },
    false,
  )

  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
    const localState = Cookies.get("localState");

    if (!localState) {
      return history.push('/');
    }

    const parseData = JSON.parse(localState);
    Cookies.set('localImprovements', JSON.stringify({ ...parseData}), getCookieMeta())
    const energyGasNew_original = localStorage.getItem('energyGasNew_original')
    localStorage.setItem('energyGasNew', energyGasNew_original)

    const value = Cookies.get("localImprovements")
      ? improvmentCardsProps(
        JSON.parse(Cookies.get("localImprovements")),
        JSON.parse(Cookies.get("localState")),
          improvementCard,
          Cookies.get("userId"),
          1,
        )
      : []
    setLoaded(1)

    clLogs = `\r\N=============== Initialize data from questionnaire ======================\r\n
    >>> User Id       : ${Cookies.get("userId")}\r\n
    >>> Initial data  : ${JSON.stringify(Cookies.get("localState"), null, 4)}\r\n
    ======================================================\r\n`

    clLogs += `\r\N=============== Improvement Cards Initialization  ======================\r\n
    >>> User Id       : ${Cookies.get("userId")}\r\n
    >>> Data  : ${value}\r\n
    ======================================================\r\n`

    axios.post(`${APP_URL}/calculation/frontend-logs`, {
      frontendLogs: clLogs,
      userId: Cookies.get("userId"),
    })

    return () => {
      //Cookies.remove('localImprovements', getCookieMeta())
    }
  }, [])

  const getCall = () => {
    axios.get(`${APP_URL}/dimensions`).then((response) => {
      setState((st) => ({
        ...st,
        buildingData: response.data,
      }))
    })
  }

  const parameters = JSON.parse(localStorage.getItem('parameters'))
  const params = parameters?.data?.data?.[0] || []
  const maximumbedragEnergielening =
    params.find((param) => param?.name === 'Maximumbedrag energielening')
      ?.value || 0

  localStorage.setItem('maximumbedragEnergielening', maximumbedragEnergielening)

  const optionNo = localStorage.getItem('optionNo')
  useEffect(() => {
    getCall()
    localStorage.setItem('split', false)
    localStorage.setItem('pagesEnergyElec', 0)
    localStorage.setItem('pagesEnergyGas', 0)
  }, [])

  useEffect(() => {
    if (state.buildingData.length > 0)
      dispatch(
        setHeatLossData(
          Cookies.get("userId"),
          JSON.parse(Cookies.get("localState")),
          state.buildingData,
          true,
        ),
      )
  }, [state.buildingData])

  useEffect(() => {
    if (Cookies.get("localImprovements") && state.buildingData.length > 0) {
      dispatch(
        setHeatLossData(
          Cookies.get("userId"),
          JSON.parse(Cookies.get("localImprovements")),
          state.buildingData,
        ),
      )
    }
  }, [Cookies.get("localImprovements"), Cookies.get("userId"), state.buildingData])

  const getColorDots = () => {
    fetch(`${APP_URL}/improvementcolor`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => setColorDot(data))
  }

  useEffect(() => {
    getColorDots()
  }, [])
  // set Actual Value of label
  useEffect(() => {
    if (epcVal == '') {
      setEpcVal(heatLossData.primairEnergieverbruikPerM2)
    } else {
      setEpcVal('')
    }
  }, [actualEpcLabel])

  if (!loaded) {
    return (
      <div className="spinner a-spinner">
        <span className="a-spinner__circle" />
        <div className="a-spinner__text">&hellip;loading</div>
      </div>
    );
  }

  return (
    <>
      <a className="skiplink visually-hidden o-header__button-skip" href="result#main">
        Skip to content
      </a>
      <div className="container result-container u-margin-bottom-xl">
        <Header />
        <main id="main" className="content-container">
          <div className="result-content u-container">
            <h1 className="visually-hidden" ref={h1Ref}>
              Resultaat
            </h1>
            <InfoCard infoCard={infoCard} />
            <div className="grid mt-2">
              {improvmentCards.map((card) => {
                const savedAnswerNo = Cookies.get('localState');
                const valid = Object.keys(
                  JSON.parse(savedAnswerNo)[card.pageIndex] || [],
                ).length
                return valid ? (
                  <div key={card.title} className="grid-col-4">
                    <ImprovementCard
                      modal={improvementModal}
                      type={card.type}
                      title={card.title}
                      subtitle={card.subtitle}
                      image={card.image}
                      status={
                        card.status === 'green'
                          ? colorDot[0]
                          : card.status === 'red'
                          ? colorDot[1]
                          : card.status === 'orange'
                          ? colorDot[2]
                          : colorDot[3]
                      }
                      pages={Cookies.get("localState")}
                      pageIndex={card.pageIndex}
                      buttonText={card.buttonText}
                      btnEditText={card.btnEditText}
                      consumptions={state.consumptions}
                      buildingData={state.buildingData}
                      resultSetState={setState}
                      isAreaUpdated={state.isAreaUpdated}
                    />
                  </div>
                ) : null
              })}
            </div>
            <OverviewSavingCard
              savingCard={savingCard}
              values={overviewValues}
              maximumbedragEnergielening={maximumbedragEnergielening}
              page6Answer={Cookies.get('localState')}
              localState={Cookies.get('localState')}
              localImprovements={Cookies.get("localImprovements")}
              improvmentCards={improvmentCards}
            />
            {heatLossData.kleurenbalk ? (
              <div className="heat-loss-container block text-center">
                <div className="heat-loss-wrapper">
                  {actualEpcLabel === heatLossData.label ? (
                    Cookies.get("bespaar") && Number(Cookies.get("bespaar")) > 0 ? (
                      <h4
                        className="heat-loss-title"
                        dangerouslySetInnerHTML={{
                          __html: `${`${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}, ${resultLabelData.epcCard.epc_img_title_current_text} ${heatLossData.label}`} `,
                        }}
                      />
                    ) : (
                      <h4
                        className="heat-loss-title"
                        dangerouslySetInnerHTML={{
                          __html: `${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}`,
                          __html: `${`${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}`} `,
                        }}
                      />
                    )
                  ) : (
                    <h4
                      className="heat-loss-title"
                      dangerouslySetInnerHTML={{
                        __html: `${`${resultLabelData.epcCard.epc_img_title} ${actualEpcLabel}, ${resultLabelData.epcCard.epc_img_title_mid_text} ${heatLossData.label} ${resultLabelData.epcCard.epc_img_title_last_text}`}`,
                      }}
                    />
                  )}
                  <div className="epc-container u-margin-bottom">
                    <div className="epc-label">
                      {epcVal !== heatLossData.primairEnergieverbruikPerM2
                        ? actualEpcLabel !== heatLossData.label
                          ? getEPCLabels(
                              heatLossData.label,
                              heatLossData.primairEnergieverbruikPerM2,
                              actualEpcLabel,
                              epcVal,
                              '',
                            )
                          : getEPCLabels(
                              heatLossData.label,
                              heatLossData.primairEnergieverbruikPerM2,
                              actualEpcLabel,
                              epcVal,
                              'sameLabel',
                            )
                        : getEPCLabels(
                            heatLossData.label,
                            heatLossData.primairEnergieverbruikPerM2,
                            '',
                            '',
                            '',
                          )}
                    </div>
                    <div className="result-img">
                      {epc}
                      {epcMobile}
                    </div>
                  </div>

                  <hr />
                  {Cookies.get("bespaar") && Number(Cookies.get("bespaar")) > 0 ? (
                    <strong
                      style={{ float: 'left' }}
                      dangerouslySetInnerHTML={{
                        __html: `${
                          resultLabelData.epcCard.epc_left_calculation_text
                        } ${
                          resultLabelData.epcCard.epc_calculation_currency
                        } ${Math.round(Number(Cookies.get("bespaar")))} / ${
                          resultLabelData.epcCard.epc_left_calculation_unit
                        }`,
                      }}
                    />
                  ) : null}
                  <a
                    href="/advies#email-card-overview"
                    style={{ float: 'right' }}
                  >
                    {resultLabelData.epcCard.epc_link_text}{' '}
                    <i className="icon-arrow-down" />
                  </a>
                  <br style={{ clear: 'both' }} />
              
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="disclaimer-container">
              <div className="disclaimer-text">
                <p
                  className="u-margin-bottom-xs"
                  dangerouslySetInnerHTML={{
                    __html: disclaimerCard.disclaimer_description,
                  }}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: disclaimerCard.disclaimer_url_heading,
                  }}
                />
                :{' '}
                <a
                  href={disclaimerCard.disclaimer_url}
                  dangerouslySetInnerHTML={{
                    __html: disclaimerCard.disclaimer_url,
                  }}
                />
              </div>
            </div>
            <OntvangEmailCard emailCard={emailCard} />
            <AdviceCard
              adviceCard={adviceCard}
              dynamicClass="advice-card-button"
            />
            <RestartCard restartCard={restartCard} />
          </div>
        </main>
      </div>
      <Footer footer={footerLabelData} />
      <div style={{display: "none"}}>
        <PdfDom
          pdfLabelData={pdfLabelData}
          improvementLabelData={improvementCard}
          improvementModalData={improvementModal}
          heatLossDataLabel={heatLossData.label}
          heatLossDataPrimairEnergie={heatLossData.primairEnergieverbruikPerM2}
          heatLossDatakleurenbalk={heatLossData.kleurenbalk}
          actualEpcLabel={actualEpcLabel}
          epcVal={epcVal}
          disclaimerCardHeading={disclaimerCard.disclaimer_url_heading}
          disclaimerCardURL={disclaimerCard.disclaimer_url}
          resultLabelData={resultLabelData}
          optionNo={optionNo}
        />
      </div>
    </>
  )
}

export default Result
