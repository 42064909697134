import {
  homePageIcon,
  step1Image1,
  step1Image2,
  step1Image3,
  step1Image4,
  wonningImage,
  step3Image1,
  step3Image2,
  step3Image3,
  step6Image1,
  step6Image2,
  step6Image3,
  step6Image4,
  stepGasImage1,
  stepGasImage2,
  stepGasImage3,
  stepGasImage4,
  pageElecriciteImage1,
  pageElecriciteImage2,
  pageElecriciteImage3,
  pageElecriciteImage4,
  pageElecriciteImage5,
  pageElecriciteImage6,
  pageAndreBronImage1,
  pageAndreBronImage2,
  pageAndreBronImage3,
  step8Image1,
  step8Image2,
  step8Image3,
  step8Image4,
  step11Image1,
  step11Image2,
  step11Image3,
  step11Image4,
  step11Image5,
  step12Image1,
  step12Image2,
  step12Image3,
  step12Image4,
  step13Image1,
  step13Image2,
  step13Image3,
  step13Image4,
  step14Image1,
  step14Image2,
  step14Image3,
  step15Image1,
  step15Image2,
  step15Image3,
  step15Image4,
  step17Image1,
  step17Image2,
  step17Image3,
  step18Image1,
  step18Image2,
  step18Image3,
  step19Image1,
  step19Image2,
  step19Image3,
  step20Image1,
  step20Image2,
  step20Image3,
  step21Image1,
  step21Image2,
  step21Image3,
  step21Image4,
  step21Image5,
  step22Image1,
  step22Image2,
  step24Image1,
  step24Image2,
  step25Image1,
  step25Image2,
  step27Image1,
  step27Image2,
  step28Image1,
  step28Image2,
  step28Image3,
  step28Image4,
  step29Image1,
  step29Image2,
  step30Image1,
  step30Image2,
  step31Image1,
  step31Image2,
  verwarmingImage,
  isolatieImage,
  energyImage,
  climateImage,
} from '../images/svg'
import stepsJson from './questionnaire.json'
import homeJson from './home.json'
import resultJson from './result.json'
import adviceJson from './advice.json'
import pageNotFound from './pageNotFound.json'
import meerInfoModal from './meerInfoModal.json'
import { API_URL } from '../environment'

export const pageTitles = {
  home: 'Antwerpen: Check je huis',
  questionnaire1: 'Type woning | Antwerpen: Check je huis',
  questionnaire2: 'Bouwjaar | Antwerpen: Check je huis',
  questionnaire3: 'Oppervlakte | Antwerpen: Check je huis',
  questionnaire4: 'Bewoner | Antwerpen: Check je huis',
  questionnaire5: 'Bewoners | Antwerpen: Check je huis',
  questionnaire6: 'Verwarming | Antwerpen: Check je huis',
  questionnaire8: 'Verwarmingselementen | Antwerpen: Check je huis',
  questionnaire9: 'Verwarming leefruimtes | Antwerpen: Check je huis',
  questionnaire10: 'Verwarming slaapkamers | Antwerpen: Check je huis',
  questionnaire11: 'Warm water | Antwerpen: Check je huis',
  questionnaire12: 'Hoofddak | Antwerpen: Check je huis',
  questionnaire13: 'Bijdak | Antwerpen: Check je huis',
  questionnaire14: 'Verwarmde ruimtes | Antwerpen: Check je huis',
  questionnaire15: 'Ramen leefruimte | Antwerpen: Check je huis',
  questionnaire16: 'Ramen slaapkamers | Antwerpen: Check je huis',
  questionnaire17: 'Voorgevel | Antwerpen: Check je huis',
  questionnaire18: 'Achtergevel | Antwerpen: Check je huis',
  questionnaire19: 'Zijgevels | Antwerpen: Check je huis',
  questionnaire20: 'Vloerisolatie | Antwerpen: Check je huis',
  questionnaire21: 'Ventilatie | Antwerpen: Check je huis',
  questionnaire22: 'Zonnepanelen | Antwerpen: Check je huis',
  questionnaire23: 'Aantal zonnepanelen | Antwerpen: Check je huis',
  questionnaire24: 'Terugdraaiende teller | Antwerpen: Check je huis',
  questionnaire25: 'Digitale meter | Antwerpen: Check je huis',
  questionnaire26: 'Energieverbruik en -productie | Antwerpen: Check je huis',
  questionnaire27: 'Groendak | Antwerpen: Check je huis',
  questionnaire28: 'Voor- of achtertuin | Antwerpen: Check je huis',
  questionnaire29: 'Regenwaterput | Antwerpen: Check je huis',
  questionnaire30: 'Verharding | Antwerpen: Check je huis',
  questionnaire31: 'Klimaat | Antwerpen: Check je huis',
  result: 'Resultaat | Antwerpen: Check je huis',
}

export const cityOptions = [
  '2000 Antwerpen Centrum',
  '2018 Antwerpen Zuid',
  '2020 Antwerpen Kiel',
  '2030 Antwerpen Luchtbal',
  '2040 Antwerpen Berendrecht Zandvliet Lillo',
  '2050 Antwerpen Linkeroever',
  '2060 Antwerpen Noord',
  '2100 Deurne',
  '2140 Borgerhout',
  '2170 Merksem',
  '2180 Ekeren',
  '2600 Berchem',
  '2610 Wilrijk',
  '2660 Hoboken',
]

export const TOTAL_PAGES = 32

export const Images = {
  step1Image1,
  step1Image2,
  step1Image3,
  step1Image4,
  step3Image1,
  step3Image2,
  step3Image3,
  step6Image1,
  step6Image2,
  step6Image3,
  step6Image4,
  stepGasImage1,
  stepGasImage2,
  stepGasImage3,
  stepGasImage4,
  pageElecriciteImage1,
  pageElecriciteImage2,
  pageElecriciteImage3,
  pageElecriciteImage4,
  pageElecriciteImage5,
  pageElecriciteImage6,
  pageAndreBronImage1,
  pageAndreBronImage2,
  pageAndreBronImage3,
  step8Image1,
  step8Image2,
  step8Image3,
  step8Image4,
  step11Image1,
  step11Image2,
  step11Image3,
  step11Image4,
  step11Image5,
  step12Image1,
  step12Image2,
  step12Image3,
  step12Image4,
  step13Image1,
  step13Image2,
  step13Image3,
  step13Image4,
  step14Image1,
  step14Image2,
  step14Image3,
  step15Image1,
  step15Image2,
  step15Image3,
  step15Image4,
  step17Image1,
  step17Image2,
  step17Image3,
  step18Image1,
  step18Image2,
  step18Image3,
  step19Image1,
  step19Image2,
  step19Image3,
  step20Image1,
  step20Image2,
  step20Image3,
  step21Image1,
  step21Image2,
  step21Image3,
  step21Image4,
  step21Image5,
  step22Image1,
  step22Image2,
  step24Image1,
  step24Image2,
  step25Image1,
  step25Image2,
  step27Image1,
  step27Image2,
  step28Image1,
  step28Image2,
  step28Image3,
  step28Image4,
  step29Image1,
  step29Image2,
  step30Image1,
  step30Image2,
  step31Image1,
  step31Image2,
  homePageIcon,
  wonningImage,
  verwarmingImage,
  isolatieImage,
  energyImage,
  climateImage,
}

export const steps = stepsJson

export const pageStaticData = {
  3: {
    info: 'Ken je de exacte waarde van je bewoonbare oppervlakte? Vul deze dan hier in.',
    errorMessage: 'Vul een getal in kleiner dan 10.000',
  },
  23: {
    info: '',
    errorMessage:
      'Uw antwoord moet bestaan uit cijfers en moet een geheel getal van 1 tot en met 100 zijn.',
  },
  26: {
    general: {
      info: '',
      errorMessage:
        'Uw antwoord moet bestaan uit cijfers en moet een geheel getal tussen 0 en 100000',
    },
    energieproductie: {
      info: '',
      errorMessage:
        'Uw antwoord moet bestaan uit cijfers en moet een geheel getal zijn van 0 t.e.m. 100',
    },
  },
}

export const homePageJson = homeJson

export const resultPageJson = resultJson

export const advicePageJson = adviceJson

export const pageNotFoundJson = pageNotFound

export const meerInfoModalJson = meerInfoModal

export const APP_URL = API_URL

export const INPUT_KEYS = {
  page_3_input_1_title: 'oppervlakte_input',
  page_23_input_1_title: 'aantal_zonnepanelen',
  page_26_input_1_title: 'aardgasverbruik',
  page_26_input_2_title: 'elektriciteitsverbruik',
  page_26_input_3_title: 'zonnepanelen',
  page_26_input_4_title: 'energieproductie',
}

export const MONTHLY_LOAN = 0.0106065604651

export const DUTCH_DAYS = [
  'maandag',
  'dinsdag',
  'woensdag',
  'donderdag',
  'vrijdag',
  'zaterdag',
  'zondag',
]

export const DUTCH_MONTHS = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
]
